<template>
  <div id="app-introduction" class="page">
    <div class="container">
      <div class="guestLogin" @click="onClickStart">{{ $__t('둘러보기') }}</div>
      <carousel ref="carousel" slideType="slide" :pager="true" :zoomable="true">
        <carousel-item v-for="(page, index) in pages" :key="`${page.step}`">
          <div class="content">
            <div
              class="text primary"
              :style="{ 'word-break': ['ko', 'en'].indexOf($store.state.lang) > -1 ? 'keep-all' : 'break-all' }"
              v-html="page.primaryText"
            ></div>
            <div
              class="text secondary"
              :style="{ 'word-break': ['ko', 'en'].indexOf($store.state.lang) > -1 ? 'keep-all' : 'break-all' }"
              v-html="page.secondaryText"
            ></div>
            <div ref="image" class="image-frame" :style="`min-height: ${imageHeight}px`">
              <img :src="page.imageSrc" :alt="`LugStay App Image Step${index + 1}`" class="image" />
            </div>
          </div>
        </carousel-item>
      </carousel>
      <div class="button-area">
        <!-- <button type="button" class="ui-submit-button activated" v-button @click="onClickStart">
          <span class="ui-submit-button__text"> 물품 보관 시작하기 </span>
        </button> -->
        <router-link :to="{ name: 'AuthLogin' }">
          <button type="button" class="login-button" v-button>
            <span class="login-button__text"> {{ $__t('로그인') }} </span>
          </button>
        </router-link>
        <router-link :to="{ name: 'AuthRegisterTerms' }">
          <button type="button" class="register-button" v-button>
            <span class="register-button__text"> {{ $__t('가입하기') }} </span>
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from '@/components/modules/LugCarousel/Body';
import CarouselItem from '@/components/modules/LugCarousel/Item';

export default {
  data() {
    return {
      pages: [
        {
          step: 'step 01',
          primaryText: this.$__t('{app-introduction}.step1.primary'),
          imageSrc: `/img/app-intro-01.png`,
          secondaryText: this.$__t('{app-introduction}.step1.secondary')
        },
        {
          step: 'step 02',
          primaryText: this.$__t('{app-introduction}.step2.primary'),
          imageSrc: `/img/app-intro-02.png`,
          secondaryText: this.$__t('{app-introduction}.step2.secondary')
        }
        // {
        //   step: 'step 03',
        //   primaryText: this.$__t('{app-introduction}.step3.primary'),
        //   imageSrc: `/img/app-intro-03.png`,
        //   secondaryText: this.$__t('{app-introduction}.step3.secondary'),
        // },
      ],

      imageHeight: 0,
      currentIndex: 0
    };
  },

  created() {
    window.addEventListener('resize', this.setImageSize);
    this.$nextTick(this.setImageSize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.setImageSize);
  },

  methods: {
    onClickStart() {
      this.$store.commit('SET_TRUE', { key: 'appIntroViewed' });
    },

    setImageSize() {
      this.$refs.image.forEach((image) => {
        let height = image.clientWidth * 1.24;
        this.imageHeight = height;
      });
    }
  },

  components: {
    Carousel,
    CarouselItem
  }
};
</script>

<style lang="scss" scoped>
#app-introduction {
  @include fsf;
  @include container('& > .container', $max-content-width);
  z-index: 1;
  background-color: $color-white;
  padding: env(safe-area-inset-top) 0 env(safe-area-inset-bottom) 0;

  & > .skip-button {
    position: fixed;
    top: 1.333 * $unit;
    right: 1.333 * $unit;

    button {
      background-color: #95ccff;
      border-radius: 1.667 * $unit;
      padding: 0.5 * $unit 0.75 * $unit;
      font-weight: 500;
      font-size: 0.917 * $unit;
      line-height: 1.4;
      color: $color-white;
    }
  }
  & > .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: unit(50) unit(16) unit(16) unit(16);

    .guestLogin {
      color: #c8c8c8;
      text-align: right;
      font-size: unit(14);
      // text-decoration: underline;
    }
    .lug-carousel::v-deep {
      height: auto;
      max-height: inherit;
      background-color: transparent;

      .lug-carousel-viewport {
        position: relative;
        height: auto;
        max-height: inherit;
        padding-bottom: unit(24);

        .lug-carousel-list {
          padding: 0;

          .lug-carousel-item {
            &::before {
              display: none;
            }
          }
        }

        .lug-carousel-pager-zoomable {
          // padding: unit(24) 0;
          // transform: translateY(100%);

          & > * {
            width: 0.667 * $unit;
            height: 0.667 * $unit;
            background-color: $color-white;
            margin: 0 0.417 * $unit;
            background-color: #a9afb3;

            &.on {
              width: 2.5 * $unit;
              border-radius: 0.875 * $unit;
              background-color: $color-primary-9;
            }
          }
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;

      .step {
        font-weight: bold;
        // font-size: 1.083 * $unit;
        font-size: 152%;
        line-height: 1.46;
        text-align: center;
        color: #ffffff;
      }

      .text {
        color: $color-white;
        word-wrap: break-word;
        word-break: keep-all;
        font-weight: bold;
        font-size: unit(24);
        line-height: 1.42;
        letter-spacing: -0.006em;

        &.primary {
          color: $color-primary-9;
        }

        &.secondary {
          margin-top: unit(12);
          color: $color-black;
        }
      }

      .image-frame {
        overflow: visible;
        width: 55%;
        margin: unit(20) auto 0 auto;

        @media screen and (min-height: 668px) {
          margin: unit(40) auto 0 auto;
        }
      }

      .image {
        margin: auto;
        object-fit: contain;
      }
    }
    & > .button-area {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: unit(10);
      position: fixed;
      left: 50%;
      transform: translateX(-50%);
      padding: unit(16);
      bottom: 0;
      width: 98%;
      // max-width: $max-content-width;
      a {
        width: 100%;
        button {
          min-height: unit(50);
          border-radius: unit(12);
          font-size: unit(16);
          height: unit(60);
          &.login-button {
            background-color: #ffffff;
            color: #404040;
            border: 1px solid #e0e0e0;
          }

          &.register-button {
            background-color: #48d9eb;
            color: #ffffff;
          }
        }
      }
    }
  }
}
</style>
